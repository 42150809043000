<template>
    <div class="user-list">
        <el-dialog title="分配权限" class="user_dialog" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
            <div>
                <template v-if="isShowEdit">
                    <el-button type="primary" size="mini" @click="editHandle('edit')">编辑</el-button>
                </template>
                <template v-else>
                    <el-button type="primary" size="mini" @click="addHandle('add')">新增</el-button>
                    <el-button type="primary" size="mini" :loading="loading" @click="saveHandle('save')">保存</el-button>
                </template>
                <el-button type="primary" size="mini" @click="emailHandle()">邮件通知</el-button>
            </div>
            <!-- 展示 -->
           <template v-if="isShowEdit">
                <div style="color:#E6A23C;margin-top: 10px;">初始密码均为：{{ password||'--' }}</div>
                 <el-table border :data="dataList" style="width: 100%" height="300">
                    <el-table-column prop="roleName"  label="角色" />
                    <el-table-column label="系统账号权限">
                        <template slot-scope="scope">
                           <span v-for="(item,i) in scope.row.systemList" :key="i"> <template v-if="i!==0">,</template>{{ item.systemName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="leader"  label="直接领导" />
                    <el-table-column prop="referenceAccount"  label="参考账号(仅限OPC&ODOO)"/>
                    <el-table-column prop="desc" label="操作备注">
                        <template slot="header">
                            <span>操作备注 <span style="color: red;">*</span></span>
                        </template>
                        <template slot-scope="scope">
                            <span>{{ scope.row.desc }}</span>
                        </template>
                    </el-table-column>
                </el-table>
           </template>
           <!-- 保存 -->
           <template v-if="!isShowEdit">
                <div style="color:#E6A23C;margin-top: 10px;">初始密码均为：{{ password||'--' }}</div>
                <el-table border :data="assginEditList" style="width: 100%" height="300">
                    <el-table-column label="角色" width="220">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.roleId" placeholder="请选择" clearable @change="changeHandle($event,scope.row)">
                                <el-option v-for="(item,i) in seletRoleList" :key="i" :label="item.roleName" :value="item.id"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="系统账号权限" width="220">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.systemSelectIids" multiple  @visible-change="visibleChangeHandle($event,scope.row)"  placeholder="请选择" clearable>
                                <el-option v-for="(item,i) in scope.row.selectList" :key="i" :label="item.systemName" :value="item.id"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="leader"  label="直接领导" />
                    <el-table-column label="参考账号(仅限OPC&ODOO)">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.referenceAccount" :disabled="scope.row.disabled"  filterable  placeholder="请选择" remote :remote-method="(query)=>remoteMethod(query,scope.row)" clearable>
                                <el-option v-for="(item,i) in scope.row.accountList" :key="i" :label="item.name" :value="item.userId"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column  prop="desc" label="操作备注" width="220">
                        <template slot="header">
                            <span>操作备注 <span style="color: red;">*</span></span>
                        </template>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.desc" placeholder="请输入" clearable></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text"  size="mini" @click="deleteHandle(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
           </template>
        </el-dialog>
    </div>
</template>

<script>
import { 
    getUserSysPerAPI,
    sendEmailAPI,
    getSystemListAPI,
    getAllRoleListAPI,
    editUserSysPerAPI,
    getPublicUsersAPI
} from "@/api/user.js";
import {  generateUUID } from '@/utils/commen'
export default {
    props: {
        dialogVisible:{
            type:Boolean,
            default:false
        },
        assginList:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data() {
        return {
            loading:false,
            password:'',
           isShowEdit:true,
           assginEditList:[],
           dataList:[],
           seletRoleList:[],
           systemData:[],
           isShowGet:false,
           selectList:[],
           accountList:[],
           email:''
        };
    },
  async  created() {
        await this.getAllRoleListAPIData()
        await   this.getUserSysPerAPIList(this.assginList.userId)
    },
    methods: {
      async  remoteMethod(e,row){
            row.accountList=[]
         await   this.getPublicUsersAPIList(e,row)
        },
     async   getPublicUsersAPIList(e,row){ // 参考账号
            try {
                let res=await getPublicUsersAPI({key:e})
                if(res.code===0){
                    this.assginEditList.forEach(item=>{
                        if(item.deId===row.deId){
                            item.accountList=res.data
                        }
                    })
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
                
            } catch (error) {
                
            }
        },
     async   visibleChangeHandle(e,row){
                e&&await   this.getSystemListAPIList(row)
        },
      async  changeHandle(e,row){
        row.systemSelectIids=[]
        await   this.getSystemListAPIList(row)
        // let adminId=row&&this.seletRoleList.filter(item=>item.roleName==='管理员')[0].id||''
        // let isAddmin=this.assginEditList.some(item=>item.roleId===adminId)
        // if(isAddmin){
        //     row.roleId=null
        //     row.selectList=[]
        //     row.systemSelectIids=[]
        //     this.$message({
        //         type: 'warning',
        //         message: '管理员不可与其他角色共同存在！'
        //     });
        // }else{
        //     row.systemSelectIids=[]
        //     await   this.getSystemListAPIList(row)
        // }
        },
        async getSystemListAPIList(row){ // 根据角色id-查询所有系统
            try {
                let res=await getSystemListAPI({id:row.roleId})
                this.assginEditList.forEach(item=>{
                    if(item.deId===row.deId){
                        // item.systemSelectIids=[]
                        item.selectList=[]
                        item.selectList=res.data||[]
                    }
                })
            } catch (error) {
                
            }
        },
        async  getAllRoleListAPIData(){ // 角色列表
            let res=await getAllRoleListAPI()
            if(res.code===0){
                this.seletRoleList=res.data
            }
        },
    async    getUserSysPerAPIList(userId){ // 详情
            try {
                this.dataList=[]
                let res=await getUserSysPerAPI({userId:userId})
                if(res.code===0){
                    this.password=res.data.password
                    res.data.list.forEach((item,index)=>{
                        if(item.referenceAccount===0){
                            item.referenceAccount=''
                        }
                        let systemSelectIids=[]
                        item.systemList&&item.systemList.forEach(m=>{
                            m.id=m.systemName
                            systemSelectIids.push(m.systemName)
                        })
                        let arr=this.seletRoleList.filter(z=>z.id===item.roleId)[0].roleName
                        this.dataList.push({
                            deId:generateUUID(),
                            roleName:arr,
                            ...item,
                            systemSelectIids:systemSelectIids,
                            referenceAccountNew:item.referenceAccount,
                            referenceAccount:item.referenceName
                        })
                    })
                }
            } catch (error) {
            }
        },
        editHandle(e){
            this.isShowEdit=false
            this.assginEditList=[]
            this.dataList.forEach(item=>{

                this.assginEditList.push({
                    selectList:item.systemList,
                    accountList:[],
                    ...item,
                    disabled:true,
                    desc:''
                })
            })
        },
        addHandle(e){
            // this.isShowEdit=true
            this.assginEditList.push({
                selectList:[],
                accountList:[],
                isAdd:true,
                deId:generateUUID(),
                roleId:null,
                systemList:[],
                systemSelectIids:[],
                referenceAccount:null,
                userId:this.assginList.userId,
                leader:this.assginList.directLeadership||''
            })
        },
     async   saveHandle(e){
          await  this.editUserSysPerAPIList()
        },
        async editUserSysPerAPIList(){
            try {
                let arr=[]
                let list=[]
                let newData=[]
                this.assginEditList.forEach(item=>{
                   list=item.selectList?.length>0&&item.selectList?.filter(m=>item?.systemSelectIids?.includes(m.id))
                    newData.push({
                        ...item,
                        list:list
                    })
                })
                newData.forEach(item=>{
                    let str=[]
                    item.list.length>0&&item.list.forEach(m=>{
                            str.push({
                                    systemName:m.systemName
                                })
                        })
                    arr.push({  
                        roleId:item.roleId,
                        referenceAccount:item.referenceAccount?((typeof (item.referenceAccount))==='number'?item.referenceAccount:item.referenceAccountNew):'',
                        desc:item.desc,
                        systemList:str
                    })
                })
                let params={
                    // menuId:7,
                    menuCode:'edit_permission',
                    userId:this.assginList.userId,
                    data:arr
                }
                this.loading=true
                let res=await editUserSysPerAPI(params)
                if(res.code===0){
                    const h = this.$createElement;
                    let arr=res.data.message.split('-')
                    let str=[]
                    arr.forEach(item=>{
                        str.push(
                            h('div', null, `${item}`)
                        )
                    })
                   if( res.data.states){
                    this.$msgbox({
                        title: '操作提示',
                        message: h('p', null,str ),
                        confirmButtonText: '确定',
                    })
                    this.isShowEdit=true 
                    await   this.getUserSysPerAPIList(this.assginList.userId)
                    this.isShowGet=true
                   }else{
                    this.$msgbox({
                        title: '操作提示',
                        message: h('p', null, [
                            h('div', null, `${res.data.message}`),
                            h('div', { style: 'color: "#E6A23C"' }, `${res.data.errorMsg}`)
                        ]),
                        confirmButtonText: '确定',
                    })
                   }
                   
                    
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });   
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });  
            } finally{
                this.loading=false
            }
        },
        emailHandle(){
            this.$prompt('请输入邮箱', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
                inputErrorMessage: '邮箱格式不正确',
                inputValue:this.assginList.email
            }).then(({ value }) => {
               this.sendEmailAPIList(value)
            }).catch(() => {
                    
            })
        },
     async   sendEmailAPIList(email){
        let newData=[]
        if(this.isShowEdit){
            newData=this.dataList
        }else{
            newData=this.assginEditList
        }
            try {
                let arr=[]
                let str=[]
                newData.forEach(item=>{
                    item.systemSelectIids.forEach(m=>{
                        str.push({
                            systemName:m
                        })
                    })
                    arr.push({
                        systemList:str,
                    })
                })
                let params={
                    email:email,
                    userId:this.assginList.userId,
                    data:arr
                }
                let res=await sendEmailAPI(params)
                if(res.code===0){
                    this.$message({
                        type: 'success',
                        message: '发送成功!'
                    });
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });
                }
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });
            }
        },
        deleteHandle(row){
            this.$confirm('是否确认删除此项?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.assginEditList= this.assginEditList.filter(item=>item.deId!==row.deId)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
        },
        handleClose(){
            if(!this.isShowEdit){
                this.isShowEdit=true
            }else{
                this.$emit('changeVisible',this.isShowGet)
            }
        }
    },
};
</script>

<style scoped lang="scss">
.user-list {
    // height: 400px;
    // background: pink;
    // .user_dialog{
    //     height: 600px;
    //     background: red;
    // }
}
</style>