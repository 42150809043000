<template>
    <div class="user-list">
        <!-- 筛选项 -->
        <el-form :inline="true" size="mini" :model="form" ref="filterFormRef" label-width="100px">
            <el-form-item label="关键词搜索:" prop="keyword">
                <el-input style="width: 200px;" v-model="form.keyword" placeholder="请输入工号/邮箱/手机号/姓名" clearable></el-input>
            </el-form-item>
            <el-form-item label="账号状态:" prop="activationStatus">
                <el-select v-model="form.activationStatus" placeholder="请选择账号状态" clearable>
                    <el-option label="启用" value="1"></el-option>
                    <el-option label="停用" value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="用户角色:" prop="roleId">
                <el-select v-model="form.roleId" placeholder="请选择用户角色" clearable>
                    <el-option v-for="(item,i) in seletRoleList" :key="i" :label="item.roleName" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间:" prop="timeData">
                <el-date-picker  v-model="form.timeData"  type="datetimerange"  range-separator="至" :default-time="['00:00:00','23:59:59']"  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="账号停用日期:" prop="deactivationDate">
                <el-date-picker  v-model="form.deactivationDate"  type="date"   placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item label="架构:" prop="deptCode">
                    <el-cascader placeholder="请选择架构"  :props="{ checkStrictly: true }" v-model="form.deptCode" :options="departmentList" filterable></el-cascader>
            </el-form-item>
            <el-form-item label="直接领导:" prop="lineManager">
                <el-input style="width: 200px;" v-model="form.lineManager" placeholder="请输入直接领导" clearable></el-input>
            </el-form-item>
            <el-form-item style="margin-left: 20px;">
                <el-button type="primary" icon="el-icon-search" @click="searchList">查询</el-button>
                <el-button icon="el-icon-refresh-left" @click="resetFilterForm">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- 表格内容 -->
        <el-table :data="tableData" border style="width: 100%;" v-loading="loading">
            <el-table-column fixed="left" prop="id" label="ID" width="80"></el-table-column>
            <el-table-column fixed="left" prop="jobNumber" label="工号"  width="80"></el-table-column>
            <el-table-column prop="belongingArchitecture" label="所属架构"  width="160"></el-table-column>
            
            <el-table-column prop="name" label="姓名" width="100">
                <template slot-scope="scope">
                    <div>{{ scope.row.name }}</div>
                    <div v-if="getCurrentTime(scope.row.dateOfEmployment)" class="item_new">NEW</div>
                </template>
            </el-table-column>
            <el-table-column prop="activationStatus" label="账号状态" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.activationStatus == 0 ? "停用" : "启用" }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="directLeadership" label="直接领导" width="120"></el-table-column>
            <el-table-column prop="deptLeader" label="部门领导" width="120"></el-table-column>
            <el-table-column prop="email" label="邮箱" width="160"></el-table-column>
            <el-table-column prop="mobilePhone" label="手机号" width="160"></el-table-column>
            <el-table-column prop="userRole" label="用户角色" width="160"></el-table-column>
            <el-table-column prop="systemPermissions" label="开通账号系统" width="160"></el-table-column>
            <el-table-column prop="deactivationDate" label="账号停用日期" ></el-table-column>
            <el-table-column prop="createAt" label="用户信息创建时间" ></el-table-column>
            <el-table-column prop="updateAt" label="用户信息更新时间"></el-table-column>
            <el-table-column fixed="right" label="操作" width="120" alig="left">
                <template slot-scope="scope">
                    <el-row>
                        <el-col :span="24">
                            <el-button  type="primary" size="mini" @click="editHandle(scope.row)">
                                编辑信息
                            </el-button>
                        </el-col>
                        <el-col :span="24">
                            <el-button style="margin: 4px 0;" type="primary" size="mini" @click="lookHandle(scope.row)">
                                分配权限
                            </el-button>
                        </el-col>
                        <el-col :span="24">
                            <el-button type="primary"  size="mini" plain @click="resetHandle(scope.row)">
                                重置密码
                            </el-button>
                        </el-col>
                        <el-col :span="24">
                            <el-button style="margin: 4px 0;" :type="scope.row.activationStatus ===0?'warning':''" plain  size="mini" @click="stopEnableHandle(scope.row)">
                                {{ scope.row.activationStatus ===0?'启用':'停用'}}
                            </el-button>
                        </el-col>
                        <el-col :span="24">
                            <el-button type="primary" size="mini" plain @click="operatingLogHandle(scope.row)">
                                操作记录
                            </el-button>
                        </el-col>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>
        <!-- 表格内容 -->
        <!-- 分页 -->
        <el-pagination
                small
                @current-change="handleCurrentChange"
                :page-size="limit"
                layout="total, prev, pager, next, jumper"
                :total="total"
                :current-page="currentPage">
        </el-pagination>
        <el-dialog
                title="重置密码"
                :visible.sync="recordsVisibleReset"
                width="40%"
                @close="recordsVisibleCloseReset"
        >
            <el-form ref="resetForm" :model="resetForm" label-width="120px">
                <el-form-item label="重置密码:" prop="password" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]" style="width: 100%">
                    <el-input type="input" v-model="resetForm.password"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelDialogHandle">取 消</el-button>
                <el-button type="primary" @click="confirmDialogHandle">确 定</el-button>
            </span>
        </el-dialog>
        <OperationLog v-if="dialogVisibleLog" :dialogVisible="dialogVisibleLog" :logForm="logForm" :recordsList="recordsList" @changeVisible="handleLogClose" /> 
        <Edit :dialogVisible="dialogVisibleEdit" :editForm="editForm" @changeVisible="handleEditClose" />
        <AssignPermissionsDialog v-if="dialogVisibleAssign" :dialogVisible="dialogVisibleAssign" :assginList="assginList" @changeVisible="handleAssignClose" /> 
    </div>
</template>

<script>
import OperationLog from './operationLog.vue'
import AssignPermissionsDialog from './assignPermissionsDialog.vue'
import Edit from './edit.vue'
import { 
    getUserListByConditionApi,
    getAllRoleListAPI,
    updateUserInfoByUserIdApi
} from "@/api/user.js";
import {  querySysOrgTree } from "@/api/role.js";

import {
  getItem,
  setItem
} from '@/utils/storage'
import {  userDepartmentTree } from '@/utils/commen'
export default {
    // name: "menu",
    components: { OperationLog, Edit, AssignPermissionsDialog },
    props: {},
    data() {
        return {
            seletRoleList:[],
            assginList:{},
            editForm:{},
            logForm:{},
            dialogVisibleAssign:false,
            dialogVisibleEdit:false,
            resetForm:{ password:'' },
            recordsVisibleReset:false,
            recordsList:[],
            dialogVisibleLog: false,
            type:'',
            menuForm:{},
            meuVisible:false,
            tableData:[],
            loading: false,
            form: {
                keyword: "",
                activationStatus: "",
                roleId:'',
                timeData:[],
                deactivationDate:'',
                lineManager:'',
                deptCode:[]
            },
            total: 0,
            currentPage: 1,
            limit: 10,
            rules:{
                password: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                age2: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ],
                age3: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ]
            },
            resetList:{},
            departmentList:getItem('departmentList') || []
        };
    },
   async created() {
        await this.getAllRoleListAPIData()
       await this.querySysOrgTreeList()
        await this.getList()
    },
    methods: {
        getCurrentTime(time){
            const date = new Date();
            const formattedDate = date.toLocaleDateString('en-CA'); 
            if(time==formattedDate){
                return true
            }else{
                return false
            }
        },
        async querySysOrgTreeList(){
            let res=await querySysOrgTree()
            if(res.code===0){
                this.departmentList=[]
                this.departmentList.push(res.data)
                this.departmentList=  userDepartmentTree(this.departmentList)
                setItem('departmentList',this.departmentList)
            }
        },
      async  getAllRoleListAPIData(){
            let res=await getAllRoleListAPI()
            if(res.code===0){
                this.seletRoleList=res.data
            }
        },
        async  getList(){ 
            try {
                this.loading=true
                let createTimeStart=''
                let createTimeEnd=''
                if(this.form.timeData?.length>0){
                    createTimeStart=this.form.timeData[0]
                    createTimeEnd=this.form.timeData[1]
                }
                let params={
                    current:this.currentPage,
                    size:this.limit,
                    lineManager:this.form.lineManager,
                    keyWord:this.form.keyword,
                    roleId:this.form.roleId,
                    createTimeStart,
                    createTimeEnd,
                    deptCode:this.form.deptCode?this.form.deptCode[this.form.deptCode.length-1]:'',
                    activationStatus:this.form.activationStatus?Number(this.form.activationStatus):null,
                    deactivationDate:this.form.deactivationDate
                }
                this.tableData=[]
                let res =await getUserListByConditionApi(params)
                if(res.code===0){
                    this.tableData=res.data.records
                    this.total=res.data.total
                    this.currentPage=res.data.current
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'失败!'}`
                    });
                }
               
            } catch (error) {
                this.$message({
                        type: 'error',
                        message: `${error||'操作失败!'}`
                    });
            } finally{
                this.loading=false
            }
        },
     async   handleAssignClose(value){
            this.dialogVisibleAssign=false
            value&&await this.getList()
        },
       async handleEditClose(value){
            this.dialogVisibleEdit=false
            value&&await this.getList()
        },
        stopEnableHandle(row){
            this.$confirm(`您确认要${row.activationStatus===0?'启用':'停用'}该账号吗?停用后该账号的所有系统${row.activationStatus===0?'均可恢复':'将不可'}登录`,  `${row.activationStatus===0?'启用':'停用'}`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async() => {
                let params={
                        userId: row.userId,
                        // menuId:9,
                        menuCode:'edit_user',
                        status:row.activationStatus===0?1:0
                    }
                await  this.updateUserInfoByUserIdApiList(params,2)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        resetHandle(row){ // 重置密码
            this.resetList=row
            this.recordsVisibleReset=true
        },
        async  updateUserInfoByUserIdApiList(params,type){
            try {
                let res=await updateUserInfoByUserIdApi(params,type)
                if(res.code===0){
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    if(type===3){
                        this.recordsVisibleReset=false
                    }
                    await this.getList()
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                
            }
        },
        confirmDialogHandle(){
            this.$refs.resetForm.validate(async (valid) => {
                if(valid){
                    let params={
                        userId: this.resetList.userId,
                        // menuId:9,
                        menuCode:'edit_user',
                        password:this.resetForm.password
                    }
                await  this.updateUserInfoByUserIdApiList(params,3)
                    this.recordsVisibleReset=false
                }else{
                    return false
                }
            })
        },
        recordsVisibleCloseReset(){
            this.resetForm.password=''
            this.recordsVisibleReset=false
            this.$refs['resetForm'].resetFields();
        },
        cancelDialogHandle(){
            this.resetForm.password=''
            this.recordsVisibleReset=false
            this.$refs['resetForm'].resetFields();
        },
      async  operatingLogHandle(row){ // 操作记录
            this.dialogVisibleLog=true
            this.logForm=row
        },
       async handleLogClose(value){
            this.dialogVisibleLog=value
        },
        submit(){
            this.$refs.refMenu.validate(async (valid) => {
                this.meuVisible=false
                if (!valid) return;
            })
        },
        meuVisibleClose(m){
            this.meuVisible=false
            this.clearForm()
        },
        clearForm(){
            Object.keys(this.menuForm).forEach(item=>{
                this.menuForm[item]=''
            })
        },
        dislogCancelHnadle(){ // 弹框的取消
            this.meuVisible = false,
            this.clearForm()
        },
        // 配置菜单
        meuVisibleHnadle(){
            this.type='add'
            this.meuVisible=true
        },
        // 编辑用户
        editHandle(row) {
            this.dialogVisibleEdit=true
            this.editForm=row
        },
        lookHandle(row){ 
            this.dialogVisibleAssign=true
            this.assginList=row
        },
         // 搜索重置
      async   resetFilterForm() {
            this.$refs.filterFormRef.resetFields();
            this.currentPage=1
            await this.getList()
        },
      async  handleCurrentChange(val) {
            this.currentPage = val
            await this.getList()
        },
     async   searchList(){
            this.currentPage=1
            await this.getList()
        }
    },
};
</script>

<style scoped lang="scss">
.user-list {
    .item_new{
        background:red;
        color:#fff;
        font-size:12px;
        width: 40px;
        height: 18px;
        line-height: 18px;
        border-radius:8px;
        text-align: center;
    }
    //overflow-y: scroll;
    .form {
        display: block;
    }

    .operate {
        margin: 25px 0;
        float: right;
    }

    .email_verify_btn {
        background: $color-theme-normal !important;
        color: #ffffff !important;
        border: 2px solid $color-theme-normal !important;
        border-radius: 0 !important;
        font-size: $font-small !important;
    }
    .dialog-footer{
        display: flex;
        justify-content: center;
    }
}
</style>