import axios from '../utils/axios'

let baseURL = '/userManagement'

// 列表
export function getUserListByConditionApi(params) {
    return axios.post(baseURL+"/getUserListByCondition",params)
}
// 编辑-停用日期-状态修改,1-日期(编辑)，2-状态，3-修改密码
export function updateUserInfoByUserIdApi(params,type) {
    return axios.post(baseURL+`/updateUserInfoByUserId/${type}`, params)
}

// 编辑中的-详情
export function queryUserDetailsByUserIdApi(params) {
    return axios.post(baseURL+'/queryUserDetailsByUserId', params)
}

// 新增角色
export function addSysRole(params) {
    return axios.post(baseURL+"/updateUserInfoByUserId",params)
}
// 获取所有角色
export function getAllRoleListAPI(params) {
    return axios.get('/sysRole/getAllRoleList', {params})
}
// 用户权限查询-刚进页面
export function getUserSysPerAPI(params) {
    return axios.post(baseURL+"/getUserSysPer",params)
}
// 根据员工id发送邮件
export function sendEmailAPI(params) {
    return axios.post(baseURL+"/sendEmail",params)
}
// 根据角色id查询系统
export function getSystemListAPI(params) {
    return axios.post(baseURL+"/getSystemList",params)
}
// 分配权限保存和编辑
export function editUserSysPerAPI(params) {
    return axios.post(baseURL+"/editUserSysPer",params)
}
// 参考账号
export function getPublicUsersAPI(params) {
    return axios.post("/userManagement/getPublicUsers",params)
}