<template>
    <div class="user-list">
        <el-dialog title="用户信息" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
            <el-form ref="editIndexForm" :model="editIndexForm" :rules="rules" label-width="120px">
                <el-row>
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="工号:" prop="jobNumber" >
                            <span>{{formList.jobNumber}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="姓名:" prop="name" >
                            <span>{{formList.name}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="Email:" prop="email"  >
                            <span>{{formList.email}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="手机号:" prop="mobilePhone" >
                            <span>{{formList.mobilePhone}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="岗位:" prop="positionId" >
                            <span>{{formList.positionId}}</span>
                        </el-form-item>
                    </el-col>
                   
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="直接领导:" prop="directLeadership" >
                            <span>{{formList.directLeadership}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" style="height: 40px;">
                        <el-form-item label="所属架构:" prop="belongingArchitecture" >
                            <span>{{formList.belongingArchitecture}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="部门领导:" prop="departmentLeaders" >
                            <span>{{formList.departmentLeaders}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="任职状态:" prop="serviceStatus" >
                            <span>{{formList.serviceStatus}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="是否销售:" prop="isSales" >
                            <span>{{formList.isSales}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" style="height: 40px;">
                        <el-form-item label="入职日期:" prop="dateOfEmployment" >
                            <span>{{formList.dateOfEmployment}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" style="height: 40px;">
                        <el-form-item label="账号停用日期:" prop="deactivationDate" style="width:100%">
                            <el-date-picker :disabled="editForm.activationStatus===0"  v-model="editIndexForm.deactivationDate"  type="date" value-format="yyyy-MM-dd"  placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelHandle">取 消</el-button>
                <el-button type="primary" :disabled="editForm.activationStatus===0" @click="confirmHandle">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { 
    updateUserInfoByUserIdApi,
    queryUserDetailsByUserIdApi
} from "@/api/user.js";
export default {
    props: {
        dialogVisible:{
            type:Boolean,
            default:false
        },
        editForm:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data() {
        return {
            editIndexForm:{
                deactivationDate:''
            },
            rules:{
                deactivationDate: [
                    { required: true, message: '请输入', trigger: 'blur' }
                ]
            },
            formList:{}
        };
    },
    created() {
    },
    watch:{
        dialogVisible(val){
            if(val){
                this.editIndexForm.deactivationDate=this.editForm.deactivationDate
                this.queryUserDetailsByUserIdApiList(this.editForm.userId)
            }
        }
    },
    methods: {
        async queryUserDetailsByUserIdApiList(userId){
            try {
                let res=await queryUserDetailsByUserIdApi({userId:userId})
                if(res.code===0){
                    this.formList=res.data
                }
            } catch (error) {
                
            }
        },
        async  updateUserInfoByUserIdApiList(params,type){
            try {
                let res=await updateUserInfoByUserIdApi(params,type)
                if(res.code===0){
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    });
                    this.$emit('changeVisible',true)
                    this.editIndexForm.deactivationDate=''
                }else{
                    this.$message({
                        type: 'error',
                        message: `${res.message||'操作失败!'}`
                    });  
                }
            } catch (error) {
                
            }
        },
        confirmHandle(){
            this.$refs.editIndexForm.validate(async (valid) => {
                if(valid){
                    let params={
                        userId:this.editForm.userId,
                        // menuId:9,
                        menuCode:'edit_user',
                        deactivationDate:this.editIndexForm.deactivationDate
                    }
                await  this.updateUserInfoByUserIdApiList(params,1)
                 
                }else{
                    return false;
                }
            })
        },
        cancelHandle(){
            this.$emit('changeVisible',false)
            this.editIndexForm.deactivationDate=''
            this.$refs['editIndexForm'].resetFields();
        },
        handleClose(){
            this.$emit('changeVisible',false)
            this.editIndexForm.deactivationDate=''
            this.$refs['editIndexForm'].resetFields();
        }
    },
};
</script>

<style scoped lang="scss">
.user-list {
  
}
</style>