<template>
    <div class="user-list">
        <el-dialog title="操作记录" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
            <el-table border :data="recordsList" height="400" style="width: 100%">
                    <el-table-column prop="createBy"  label="操作人" />
                    <el-table-column prop="menuName"  label="操作行为" />
                    <el-table-column prop="operationDesc"  label="操作备注" />
                    <el-table-column prop="createAt"  label="操作时间"/>
                </el-table>
        </el-dialog>
    </div>
</template>

<script>
import {  getUserPermissionLogAPI } from "@/api/log.js";
export default {
    props: {
        dialogVisible:{
            type:Boolean,
            default:false
        },
        logForm:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data() {
        return {
            recordsList:[]
        };
    },
    created() {
        this.getUserPermissionLogAPIList()
    },
    methods: {
        async getUserPermissionLogAPIList(){
            try {
                let res=await getUserPermissionLogAPI({userId:this.logForm.userId})
                if(res.code===0){
                    this.recordsList=res.data
                }
            } catch (error) {
                
            }
        },
        handleClose(){
            this.$emit('changeVisible',false)
        }
    },
};
</script>

<style scoped lang="scss">
.user-list {
   
}
</style>